.slick-slider {
    position: relative;
    height: 100%;
    display: flex;
    width: 100%;
    justify-content: center;
    right: 0%;
    align-items: center;
    padding: 0 10px;
    margin-right: 10px;
  }
  .slick-list {
    position: relative;
    display: block !important;
    overflow: hidden;
    background-color: white;
    padding: 100px !important;
    width: 105%;
  }
  .slick-track {
    position: relative;
    display: flex;
  }
  .slick-slide div {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
    align-items: center;
  }
  .slick-slide .image {
    object-fit: cover;
    border-radius: 2px;
    min-width: 130px !important;
    width: 70%;
  }
  @media screen and (max-width: 580px) {

    .slick-slide div span {
      display: flex;
      align-items: center;
      align-self: center;
      width: auto;
    }
    .slick-track {
      position: relative;
      display: flex;
      width: auto;}
    .slick-slide div div span {
      display: none;
    }
    .slick-slide.slick-current div div span {
      width: auto;
      text-align: center;
      justify-content: space-between;
      text-transform: uppercase;
      color: #111;
    }
    .slick-list{
      left: -13%;
    }
  }
  .slick-slide:not(.slick-active) {
    position: relative;
    z-index: 997;
  }
  .slick-slide.slick-active:not(.slick-current) {
    position: relative;
    z-index: 999;
    transform: scale(1.2);
    left: -2.86%;
    
  }
  .slick-slide.slick-active.slick-current {
    position: relative;
    z-index: 1000;
    transform: scale(1.4);
    left: -2.5%;
    
  }
  .slick-slide.slick-active:not(.slick-current) .image,
  .slick-slide.slick-active.slick-current .image,
  .slick-slide:not(.slick-active) .image {
    width: 100% !important;
  }
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .custom-prevArrow,
  .custom-nextArrow {
    position: relative;
    z-index: 99999;
    top: -10px;
  }
  .custom-prevArrow {
    left: -10px;
  }
  .custom-nextArrow {
    right: 0px;
  }
  .custom-prevArrow:hover,
  .custom-nextArrow:hover {
    fill: red;
    cursor: pointer;
  }

  @media screen and (max-width: 580px) {
    .custom-prevArrow {
      left: 0px;
    }
    .custom-nextArrow {
      right: 50px;
    }
  }
  .image-upload>input {
    display: none;
  }
