@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.container {
  display: "flex";
  flex-direction: "row"; 
  width:'102%';
}

.slide img {
  width: 20rem;
  margin: 0 auto;
}

.slide {
  transform: scale(0.835);
  transition: transform 300ms;
  opacity: 1;
  background-color: white;
}

.activeSlide {
  transform: scale(0.92);
  opacity: 1;
  background-color: white;
}
@media screen and (max-width: 580px) {
  .slide {
    margin: auto;
    transform: scale(1.2);
  }
  .slide img{
    width: 500px;
  }
  .container{
    position: relative;
    left: 10%;
    width: 120%;
  }
}
