/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .input {
    @apply py-3 transition focus:outline-none focus:ring focus:ring-offset-2 
    w-full border-2 border-gray-700 rounded-lg;
  }
  .input-primary {
    @apply focus:ring-brand
  }
  .btn {
    @apply uppercase tracking-wider font-semibold text-sm sm:text-base
    shadow-lg hover:-translate-y-0.5 transform transition rounded-xl;
  }
  .btn-primary {
    @apply bg-brand hover:bg-brand-light focus:outline-none focus:ring focus:ring-offset-2 
     focus:ring-brand focus:ring-opacity-50 active:bg-brand-dark text-white;
  }
  .text-shadow {
    text-shadow: 2px 2px 4px gray;
  }
  .text-shadow-md {
    text-shadow: 4px 4px 8px gray;
  }
  .text-shadow-lg {
    text-shadow: 15px 15px 30px gray;
  }
  .text-shadow-none {
    text-shadow: none;
  }
}

.css-yk16xz-control {
  background-color: tomato;
  border-width: 0; 
}

.richtext {
  font-size: 18px;
}

.richtext > h1, 
.richtext > h2, 
.richtext > h3, 
.richtext > h4 { 
  color: tomato;
}

.richtext > h1 { 
  font-size: 30px;
}
.richtext > h2 { 
  font-size: 26px;
}
.richtext > h3 { 
  font-size: 20px;
}
.richtext > h4 { 
  font-size: 18px;
}

.richtext > p > strong {
  color: tomato;
}

.richtext > p > a {
  background-color: tomato;
  color: white;
  padding: 0 5px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown .dropdown-content {
  position: absolute;
}

menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.primary-color {
  background-color: #eb3313;
  color: white;
}